<script setup lang="ts">
const route = useRoute()
const isSignup = computed(() => {
  return route.fullPath === '/signup' || route.matched[0].path === '/payments/:code()'
})
</script>

<template>
  <div id="full">
    <div v-if="!isSignup" class="logo-wrapper">
      <a href @click.prevent="navigateTo('/')">
        <img src="../assets/images/logo.svg" alt="Crowdstake" title="Crowdstake" class="logo">
      </a>
    </div>
    <slot />
  </div>
</template>

<style scoped>
 #full{
   height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
 }
 .logo-wrapper {
   width: 100%;
   max-width: 430px;
 }
 a{
   align-self: start;
 }
 img.logo{
   display: block;
   width: 130px;
   margin: 0 16px 20px;
   text-align: left;
 }
</style>
